import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./Pages/Home";
import Page404 from "./Pages/Page404";
import CountryView from "./Pages/CountryView";
import CountryDetail from "./Pages/CountryDetail";
import NewspaperView from "./Pages/NewspaperView";
import NewspaperDetail from "./Pages/NewspaperDetail";
import About from "./Pages/About";
import Methodology from "./Pages/Methodology";
import MaintenancePage from "./Pages/MaintenancePage";
import Pdf from "./Pages/Pdf"
import WorldPage from "./Pages/WorldPage"
import LatestArticles from "./Pages/LatestArticlesPage";
import ReactGA from 'react-ga';

const TRACKING_ID = "G-94EW3KS2CV"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

var dateActuelle = new Date();
var hour = dateActuelle.getHours(); //24h format
var minute = dateActuelle.getMinutes();

// Afficher l'heure actuelle

const GoogleDriveRedirect = () => {
  useEffect(() => {
    // Replace this URL with the link to your Google Drive file
    const driveFileUrl = 'https://drive.google.com/file/d/1kBfl5sl88Y2mlRgvMl1zzaqljOjY23BU/view?usp=sharing';
    
    // Redirect to the Google Drive file
    window.location.href = driveFileUrl;
  }, []);

  return null; // No visible UI, as it's just a redirect
};


function App() {
  
  return (
    <>
      <Router>
      <PageTracker />
        <ToastContainer />
        {(!(hour === 4 && minute <= 10 && minute >= 0) && (
        <Routes>
          <Route path="/country-view" element={<CountryView />} />
          <Route path="/country-detail" element={<CountryDetail />} />
          <Route path="/newspaper-view" element={<NewspaperView />} />
          <Route path="/newspaper-detail" element={<NewspaperDetail />} />
          <Route path="/about" element={<About />} />
          <Route path="/methodology" element={<Methodology />} />
          <Route path="/world" element={<WorldPage />} />
          <Route path="/latestArticles" element={<LatestArticles />} />
          <Route path="/starlink" element={<GoogleDriveRedirect />} />
        
          <Route path="/" element={<Home />} />
          <Route path="*" element={<Page404 />} />
          <Route path="/routine" element={<MaintenancePage />} />
        </Routes>
        )) || (
        <Routes>
          <Route path="*" element={<MaintenancePage />} />
        </Routes>
        )}
      </Router>
    </>
  );
}

// Custom component to track page views
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-94EW3KS2CV', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null;
}

export default App;
