import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../Styles/Staff.css";
import PieChartComponent from "../graphs/PieChartComponent";
import html2canvas from "html2canvas";
// import backgroundImage from "../assets/background.png";

// import NewspaperDsipoCard from "../components/NewspaperDsipoCard"
// import NewspaperEcoCard from "../components/NewspaperEcoCard"

import share from "../assets/shareButton.png";
import copy from "../assets/copy.png";
import check from "../assets/check.png";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import cardImage from "../assets/sampleCardImage.png";
import backgroundImage from "../assets/backgroundMain.jpg";
import franceMap from "../assets/franceMap.png";
import usaMap from "../assets/usaMap.png";
import ukMap from "../assets/ukMap.png";
import japanMap from "../assets/japanMap.png";
import russiaMap from "../assets/russiaMap.png";
import chinaMap from "../assets/chinaMap.png";
import canadaMap from "../assets/canadaMap.png";
import brazilMap from "../assets/brazilMap.png";
import singaporeMap from "../assets/singaporeMap.png";
import uaeMap from "../assets/uaeMap.png";
import nigeriaMap from "../assets/nigeriaMap.png";
import australiaMap from "../assets/australiaMap.png";
import pakistanMap from "../assets/pakistanMap.png";

import AreaChartComponent from "../graphs/AreaChartComponent";
import BigSingleHorizontalBar from "../graphs/BigSingleHorizontalBar";
import { useMediaQuery } from "react-responsive";
// import SmallPieChart from "../graphs/SmallPieChart";
import SmallPieChart from "../graphs/SmallCopyNewsPieChart";

import MicroPieChart from "../graphs/MicroPieChart";
import NewspaperCountryCard from "../components/NewspaperCountryCard";
import NewspaperReaderCard from "../components/NewspaperReaderCard";
import NewspaperPoliticalCard from "../components/NewspaperPoliticalCard";
import NewspaperOwnerCard from "../components/NewspaperOwnerCard";

// import bricsImg from "../assets/newspaperStats/brics.png";
// import fiveEyesImg from "../assets/newspaperStats/fiveEyes.png";
// import nuclearImg from "../assets/newspaperStats/nuclear.png";
// import qsdImg from "../assets/newspaperStats/qsd.png";
// import unscImg from "../assets/newspaperStats/unsc.png";
// import borDispImg from "../assets/newspaperStats/borDisp.png";
// import NewspaperImportCard from "../components/NewspaperImportCard";
// import NewspaperExportCard from "../components/NewspaperExportCard";
// import NewspaperDefenseCard from "../components/NewspaperDefenseCard";
// import NewspaperTourismCard from "../components/NewspaperTourismCard";

function NewspaperDetails() {
  const [monthwiseData, setMonthwiseData] = useState([]);
  const [dataForBar, setDataForBar] = useState([]);
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [articlesNumber, setArticlesNumber] = useState(6);

  const [allArticles, setAllArticles] = useState([]);

  const [newspaperArticles, setNewspaperArticles] = useState([
    {
      image: "",
      title: "Title 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-25",
      link: "",
    },
    {
      image: "",
      title: "Title 2",
      content:
        "Praesent commodo quam id libero maximus luctus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-26",
      link: "",
    },
    {
      image: "",
      title: "Title 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-25",
      link: "",
    },
    {
      image: "",
      title: "Title 2",
      content:
        "Praesent commodo quam id libero maximus luctus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-26",
      link: "",
    },
    {
      image: "",
      title: "Title 1",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-25",
      link: "",
    },
    {
      image: "",
      title: "Title 2",
      content:
        "Praesent commodo quam id libero maximus luctus.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      date: "2023-08-26",
      link: "",
    },
  ]);

  const [newspaperData, setNewspaperData] = useState({
    name: "",
    all: 0,
    positive: 0,
    negative: 0,
    neutral: 0,
  });
  const [flagObjectSelected, setFlagObjectSelected] = useState("");
  const [newspaperStats, setNewspaperStats] = useState([]);

  const [brics, setBrics] = useState(false);
  const [fiveEyes, setFiveEyes] = useState(false);
  const [unsc, setUnsc] = useState(false);
  const [qsd, setQsd] = useState(false);
  const [nuclear, setNuclear] = useState(false);
  const [borDisp, setBorDisp] = useState(false);

  const [ecoRank, setEcoRank] = useState();
  const [diaspRank, setDiaspRank] = useState();
  const [importRank, setImportRank] = useState();
  const [exportRank, setExportRank] = useState();
  const [defenseRank, srtDefenseRank] = useState();
  const [tourismRank, setTourismRank] = useState();

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });

  //----------------------------IN THIS USE EFFECT GET COUNTRY NAME FROM LOCAL STORAGE AND GET DATA ACCORDINGLY-----------------------------

  let tempName;

  useEffect(() => {
    // Update the state based on the window width when the component mounts
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1380);
    };

    // Add an event listener to update the state when the window is resized
    window.addEventListener("resize", handleResize);

    // Initialize the state based on the window width
    handleResize();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    tempName = localStorage.getItem("hoveredNewspaper");
    const tempPositive = localStorage.getItem("hoveredNewspaperPositive");
    const tempNegative = localStorage.getItem("hoveredNewspaperNegative");
    const tempNeutral = localStorage.getItem("hoveredNewspaperNeutral");
    const tempNewsCountry = localStorage.getItem("hoveredNewspaperCountry");
    const tempNewsLogo = localStorage.getItem("hoveredNewspaperLogo");
    const tempReaders = localStorage.getItem("hoveredNewspaperReaders");
    const tempPoliticalInclination = localStorage.getItem("hoveredNewspaperPoliticalInclination");
    const tempInfo = localStorage.getItem("hoveredNewspaperInfo");
    const tempOwner = (localStorage.getItem("hoveredNewspaperOwner") === "null" ? null : localStorage.getItem("hoveredNewspaperOwner"))

    const fetchArticles = async () => {
      try {
        const requestData = {
          newspaperName: tempName,
        };

        const response = await fetch(
          "https://kutniti.watch/api/article/getAllArticlesByNewspaper",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const getData = await response.json();
          getData.sort((a, b) => {
            const dateA = new Date(a.pubDate);
            const dateB = new Date(b.pubDate);
            return dateB - dateA;
          });

          let i = 0;
          let newNewspaperArticles = [];
          while (i < Object.keys(getData).length) {
            const dateString = getData[i].pubDate;
            const date = new Date(dateString);

            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();

            const parser = new DOMParser();
            const doc = parser.parseFromString(getData[i].content, 'text/html');

            const imgElement = doc.querySelector('img');
            let image = null;
            if (imgElement) {
              image = imgElement.getAttribute('src');
            }
            const formattedDate = `${day}/${month}/${year}`;
            newNewspaperArticles.push({
              image: image,
              title: getData[i].title,
              content: getData[i].summary,
              date: formattedDate,
              type: getData[i].type,
              link: getData[i].link
            });
            i++;
          }
          let allArt = newNewspaperArticles;
          setAllArticles(allArt);
          setNewspaperArticles(newNewspaperArticles.slice(0, articlesNumber));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchArticles();

    // const getStats = async () => {
    //   try {
    //     const res = await fetch(
    //       "https://sheet.best/api/sheets/4a6a3f85-83ed-4537-886d-02d28e3b5696"
    //     );
    //     const data = await res.json();
    //     setNewspaperStats(Object.keys(data).map((key) => data[key]));
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // getStats();

    const fetchAllFlags = async () => {
      try {
        const response = await fetch(
          "https://kutniti.watch/api/country/getallCountryArticleNumber",
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const getData = await response.json();

          const uniqueCountries = [];
          const countryNames = {};

          getData.forEach((item) => {
            const { countryName, flagLogo } = item;
            if (!countryNames[countryName]) {
              countryNames[countryName] = true;
              uniqueCountries.push({ countryName, flagLogo });
            }
          });

          // console.log(uniqueCountries);
          if (tempNewsCountry === "United States") {
            tempNewsCountry = "USA";
          }

          const matchedCountry = uniqueCountries.find(
            (country) => country.countryName === tempNewsCountry
          );
          setFlagObjectSelected(matchedCountry);

          if (tempNewsCountry === "USA") {
            tempNewsCountry = "United States";
          }
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllFlags();

    setNewspaperData({
      name: tempName,
      positive: tempPositive,
      negative: tempNegative,
      neutral: tempNeutral,
      country: tempNewsCountry,
      logo: tempNewsLogo,
      readers: tempReaders,
      political: tempPoliticalInclination,
      owner: tempOwner,
      info: tempInfo,
    });

    const fetchAllCountries = async () => {
      try {
        const requestData = {
          newspaperName: tempName,
        };

        const response = await fetch(
          "https://kutniti.watch/api/newspaper/getAllDataForNewspaper",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const responseData = await response.json();

          function generateMonthlyData() {
            let months = [
              { name: "January", number: 1 },
              { name: "February", number: 2 },
              { name: "March", number: 3 },
              { name: "April", number: 4 },
              { name: "May", number: 5 },
              { name: "June", number: 6 },
              { name: "July", number: 7 },
              { name: "August", number: 8 },
              { name: "September", number: 9 },
              { name: "October", number: 10 },
              { name: "November", number: 11 },
              { name: "December", number: 12 }
            ];

            const date = new Date();
            const currentMonths = date.getMonth();

            months = Array.from({ length: 8 }, (_, i) => {
              const index = (currentMonths - 7 + i + 12) % 12; // Calculate indices for last 8 months
              return months[index];
            });


            const monthlyData = months.map((month) => {
              const monthInfo = responseData.articles[month.name];

              return {
                name: month.name,
                neg: monthInfo && monthInfo.Negative ? monthInfo.Negative : 0,
                pos: monthInfo && monthInfo.Positive ? monthInfo.Positive : 0,
                neu: monthInfo && monthInfo.Neutral ? monthInfo.Neutral : 0,
                grey: 0,
              };
            });
            return monthlyData;
          }

          const monthlyData = generateMonthlyData();

          const shortenedMonthlyData = monthlyData.map((month) => ({
            ...month,
            name: month.name.slice(0, 3), // Shorten the month name to the first 3 characters
          }));

          setDataForBar(shortenedMonthlyData);
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllCountries();
  }, [tempName]);

  //console.log(monthwiseData);


  const shareText = "Check out this awesome pie chart!"; // Change as needed
  const shareUrl = encodeURIComponent("http://kutniti.watch:8000/newspaper-view"); // Get the current URL

  const handleDownload = async () => {
    const chartRef = document.getElementById("capture"); // Get the chart element

    try {
      const canvas = await html2canvas(chartRef);
      const imageUri = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = imageUri;
      link.download = "capture.png";
      link.click();
    } catch (error) {
      console.error("Error generating image:", error);
    }
  };

  let bg = backgroundImage;
  if (newspaperData.country === "France") {
    bg = franceMap;
  } else if (newspaperData.country === "USA") {
    bg = usaMap;
  } else if (newspaperData.country === "UK") {
    bg = ukMap;
  } else if (newspaperData.country === "Japan") {
    bg = japanMap;
  } else if (newspaperData.country === "China") {
    bg = chinaMap;
  } else if (newspaperData.country === "Canada") {
    bg = canadaMap;
  } else if (newspaperData.country === "Australia") {
    bg = australiaMap;
  } else if (newspaperData.country === "Brazil") {
    bg = brazilMap;
  } else if (newspaperData.country === "Russia") {
    bg = russiaMap;
  } else if (newspaperData.country === "Pakistan") {
    bg = pakistanMap;
  } else if (newspaperData.country === "UAE") {
    bg = uaeMap;
  } else if (newspaperData.country === "Singapore") {
    bg = singaporeMap;
  } else if (newspaperData.country === "Nigeria") {
    bg = nigeriaMap;
  }
  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${bg})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    // Add other styles as needed
  };

  // const chartData = [
  //   {
  //     name: 'JAN',
  //     neg: 40,
  //     pos: 24,
  //     neu: 14,
  //     grey: 0,
  //   },
  //   {
  //     name: 'FEB',
  //     neg: 20,
  //     pos: 44,
  //     neu: 24,
  //     grey: 0,
  //   },
  //   {
  //     name: 'MAR',
  //     neg: 30,
  //     pos: 24,
  //     neu: 4,
  //     grey: 0,
  //   },
  //   {
  //     name: 'APR',
  //     neg: 50,
  //     pos: 14,
  //     neu: 24,
  //     grey: 0,
  //   },
  //   {
  //     name: 'MAY',
  //     neg: 30,
  //     pos: 34,
  //     neu: 24,
  //     grey: 0,
  //   },
  //   {
  //     name: 'JUNE',
  //     neg: 60,
  //     pos: 24,
  //     neu: 14,
  //     grey: 0,
  //   },
  //   {
  //     name: 'JULY',
  //     neg: 10,
  //     pos: 54,
  //     neu: 14,
  //     grey: 0,
  //   },
  //   {
  //     name: 'AUG',
  //     neg: 60,
  //     pos: 24,
  //     neu: 14,
  //     grey: 0,
  //   },
  //   {
  //     name: 'SEPT',
  //     neg: 0,
  //     pos: 0,
  //     neu: 0,
  //     grey: 0,
  //   },
  //   {
  //     name: 'OCT',
  //     neg: 0,
  //     pos: 0,
  //     neu: 0,
  //     grey: 0,
  //   },
  //   {
  //     name: 'NOV',
  //     neg: 0,
  //     pos: 0,
  //     neu: 0,
  //     grey: 0,
  //   },
  //   {
  //     name: 'DEC',
  //     neg: 0,
  //     pos: 0,
  //     neu: 0,
  //     grey: 0,
  //   },
  // ];

  const colors = [
    "bg-red-600",
    "bg-orange-400",
    "bg-yellow-300",
    "bg-green-300",
    "bg-green-600",
  ];
  const texts = ["0%", "25%", "50%", "75%", "100%"];

  //console.log(newspaperData);

  /////////////////////////Share handling//////////
  const websiteUrl = `https://kutniti.watch`
  const message = encodeURIComponent("Do you want to know how India is perceived in the world?\nGo check on:");
  const disagreeMessage1 = encodeURIComponent("#kutniti\nI was on kutniti.watch\nAnd I disagree with the perception of this article:\n");

  function handleTweet() {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${websiteUrl}`;
    window.open(twitterUrl, "_blank");
  }

  function handleTweetDisagreeButton(article) {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${disagreeMessage1}&url=${article.link}`;
    window.open(twitterUrl, "_blank");
  }

  function handleFb() {
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${websiteUrl}`;
    window.open(fbUrl, "_blank")
  }

  function handleLinkedIn() {
    const LinkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${websiteUrl}`;
    window.open(LinkedInUrl, "_blank")
  }

  function handleCopy() {
    const currentUrl = window.location.href;
    const tempTextarea = document.createElement("textarea");
    tempTextarea.value = currentUrl;
    document.body.appendChild(tempTextarea);
    tempTextarea.select();
    document.execCommand("copy");
    document.body.removeChild(tempTextarea);

    const button = document.getElementById("CopyButton");
    const imgElement = button.querySelector('img'); // Find the img element within the button
    imgElement.src = check;

    // You may want to reset the button text after a certain time (e.g., 2 seconds)
    setTimeout(() => {
      imgElement.src = copy;
    }, 3000);
  }

  const captureScreenshot = async () => {
    const canvas = await html2canvas(document.body);
    return canvas.toDataURL(); // Returns a data URL representing the screenshot
  };

  async function toggleShareOptions() {
    try {
      const screenshotDataUrl = await captureScreenshot();

      if (navigator.share) {
        await navigator.share({
          title: 'Share Screenshot',
          text: 'Check out this screenshot!',
          url: screenshotDataUrl,
        });
      } else {
        var shareOptions = document.getElementById("shareOptions");
        if (shareOptions.style.display === "block") {
          shareOptions.style.display = "none";
        } else {
          shareOptions.style.display = "block";
        }
      }
    } catch (error) {
      console.error('Error sharing screenshot:', error);
    }
  }

  const handleLoadMore = () => {
    if (articlesNumber < allArticles.length) {
      setArticlesNumber(articlesNumber + 6);
      setNewspaperArticles(allArticles.slice(0, articlesNumber + 6))
    }
  };

  //console.log(newspaperData.owner)
  return (
    <div style={containerStyle} className="w-full font-custom">
      <Navbar />
      <div className="flex">
        <div className="w-full m-2">
          <h1 className=" p-5 invisible">
            Providing Free spacing
          </h1>

          <div className=" lg:m-7 lg:p-5 m-2 mb-5 p-2 rounded-2xl border border-gray-600 ">
            <div className="">
              <div className="lg:w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 flex justify-between items-center rounded-xl shadow-2xl p-1 mb-5">
                <div className="flex m-1">
                  <div className="rounded-lg overflow-hidden ">
                    {newspaperData.logo && (
                      <img
                        src={newspaperData.logo}
                        alt="Newspaper Logo"
                        className=" h-10 rounded-lg"
                      />
                    ) ||
                      <div className="text-xl ml-2 ">
                        <div className="text-2xl">{newspaperData.name}</div>
                      </div>}
                  </div>
                </div>

                <div className="flex">
                  <div id="shareOptions" class="hidden flex">
                    <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleFb}>
                      <img
                        src={facebook}
                        alt="Facebook Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </div>
                    <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleTweet}>
                      <img
                        src={twitter}
                        alt="Twitter Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </div>
                    <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleLinkedIn}>
                      <img
                        src={linkedin}
                        alt="LinkedIn Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </div>
                    <div id="CopyButton" className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleCopy}>
                      <img
                        src={copy}
                        alt="Copy Button"
                        className="w-18 h-10 rounded-lg"
                      />
                    </div>
                  </div>
                  <button
                    className="bg-white cursor-pointer text-white font-bold m-auto p-auto rounded-lg shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 border"
                    onClick={toggleShareOptions}
                  >
                    <img
                      src={share}
                      alt="Share Button"
                      className="w-18 h-10 rounded-lg"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center rounded-xl shadow-md p-2 h-30 mb-5">
              <div className="text-2xl">
                {isLaptop && (
                  <p className="mx-2 mt-2 font-bold">
                    Basic info about {newspaperData.name}:
                  </p>
                )}
                {isMobile && (
                  <p className="flex justify-center text-lg text-center font-bold mb-2">
                    Basic info about {newspaperData.name}:
                  </p>
                )}
                <div className="mx-2 text-center lg:text-left text-lg">
                  {newspaperData.info}
                </div>
              </div>
            </div>

            <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center rounded-xl shadow-md p-2 h-30 mb-5 lg:flex justify-between">
              <div className="text-2xl w-full lg:w-1/3">
                {isLaptop && (
                  <p className="mx-2 mt-2 mb-4 font-bold">
                    Why {newspaperData.name} matters to India?
                  </p>
                )}
                {isMobile && (
                  <p className="flex w-full text-center text-lg font-bold mb-2">
                    Why {newspaperData.name} matters to India?
                  </p>
                )}
              </div>
              <div className="h-full w-full lg:w-[60%]">
                <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 w-full h-full">
                  {flagObjectSelected && (
                    <div>
                      <NewspaperCountryCard
                        firstValue={flagObjectSelected.flagLogo}
                        secondValue={newspaperData.country}
                      />
                    </div>
                  )}
                  {newspaperData.readers > 0 && (
                    <div>
                      <NewspaperReaderCard
                        firstValue={newspaperData.readers}
                      />
                    </div>
                  )}
                  {newspaperData.political && (
                    <div>
                      <NewspaperPoliticalCard
                        firstValue={newspaperData.political}
                      />
                    </div>
                  )}
                  {newspaperData.owner && (
                    <div>
                      <NewspaperOwnerCard
                        firstValue={newspaperData.owner}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>


            <div id="capture">
              <div
                className={` ${isWideScreen
                    ? "flex w-full space-x-12"
                    : "grid grid-cols-1 lg:grid-cols-2 lg-8"
                  }  overflow-x-auto`}
              >
                {/* 1 */}

                <div className={`${isWideScreen ? "w-1/3" : ""}`}>
                  {isMobile && (
                    <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center shadow-md rounded-xl mb-5 ">
                      <div className="m-2">
                        <p className="flex justify-center text-center  w-full text-lg font-bold ">
                          Sentiment of {newspaperData.name} towards India
                        </p>
                        <div className="flex">
                          <div className="w-1/2 flex justify-end">
                            <SmallPieChart
                              hoveredPositive={newspaperData.positive}
                              hoveredNegative={newspaperData.negative}
                              hoveredNeutral={newspaperData.neutral}
                            />
                          </div>
                          <div className="w-1/2 self-center">
                            <p className="text-custom-red m-3">
                              Negative: {newspaperData.negative}
                            </p>
                            <p className="text-custom-yellow2 m-3">
                              Neutral: {newspaperData.neutral}
                            </p>
                            <p className="text-custom-green m-3">
                              Positive: {newspaperData.positive}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isLaptop && (
                    <div className="border border-gray-300 bg-white bg-opacity-30 backdrop-blur-[3px] items-center shadow-lg rounded-xl max-h-[400px] flex w-full mb-5 mt-5 r-0">
                      <div className="pb-2 mx-auto">
                        <p className="flex justify-center text-xl mt-5 mx-5">
                          Sentiment of {newspaperData.name} towards India
                        </p>
                        <div className="">
                          <div className="flex justify-center">
                            <PieChartComponent
                              hoveredPositive={newspaperData.positive}
                              hoveredNegative={newspaperData.negative}
                              hoveredNeutral={newspaperData.neutral}
                            />
                          </div>
                          <div className="flex justify-between mx-5">
                            <p className="text-custom-red">
                              Negative: {newspaperData.negative}
                            </p>
                            <p className="text-custom-yellow2">
                              Neutral: {newspaperData.neutral}
                            </p>
                            <p className="text-custom-green">
                              Positive: {newspaperData.positive}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    {isMobile && (
                      <div className="p-2  flex bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 shadow-md rounded-xl max-w-[500px]">
                        <p className="text-gray-700 mx-4 ">
                          Articles Published by {newspaperData.name}
                        </p>

                        <div className="w-px h-6 bg-gray-800 self-center"></div>
                        <p className="text-gray-700 text-base mx-4 self-center">
                          {parseInt(newspaperData.positive) +
                            parseInt(newspaperData.negative) +
                            parseInt(newspaperData.neutral)}
                        </p>
                      </div>
                    )}
                    {isLaptop && (
                      <div className="border border-gray-300 bg-white bg-opacity-30 p-2 flex justify-center space-x-4 backdrop-blur-[3px] shadow-lg mb-5 rounded-lg w-full">
                        <p className="text-gray-700">
                          Articles Published by {newspaperData.name}
                        </p>

                        <div className="w-px h-6 bg-gray-800"></div>
                        <p className="text-gray-700 text-base">
                          {parseInt(newspaperData.positive) +
                            parseInt(newspaperData.negative) +
                            parseInt(newspaperData.neutral)}
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/* 2 */}

                <div className={` ${isWideScreen ? "w-2/3" : "w-full"}   mt-5`}>
                  <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-lg mb-5 rounded-xl w-full h-1000 overflow-x-auto">
                    <p className="flex justify-center text-xl mt-5">
                      Evolution over the months
                    </p>
                    {isLaptop && (<AreaChartComponent chartData={dataForBar} />)}
                    {isMobile && (<AreaChartComponent chartData={dataForBar} />)}
                  </div>
                </div>

              </div>
            </div>
            {isLaptop && (
              <div className="flex bg-white bg-opacity-30 backdrop-blur-[3px] rounded-2xl shadow-2xl mt-4 border border-gray-300">
                <div className="mx-5 mb-2 mt-5 p-2 w-full">
                  <div className=" my-1">
                    <h2 className="text-xl font-bold mb-5  ">
                      Latest Articles about India by {newspaperData.name}
                    </h2>
                  </div>
                  <div className="grid grid-cols-2 gap-6">
                    {newspaperArticles.map((item) => (
                      <div className="mb-2">
                        <div className="relative">
                          <a href={item.link}>
                            <div
                              className={`shadow-md z-0 border-4 p-1 m-1 hover:bg-opacity-30 bg-opacity-5 backdrop-blur-[3px] rounded-xl drop-shadow-lg w-full h-4/3  ${item.type === "Negative"
                                  ? "bg-custom-red border-custom-red"
                                  : item.type === "Neutral"
                                    ? "bg-custom-yellow2 border-custom-yellow2"
                                    : "bg-custom-green border-custom-green"
                                }`}
                            >
                              <div className="flex space-x-2">
                                <div className="w-[30%] p-1">
                                  {item.image && (<img
                                    src={item.image}
                                    alt="illustration of article"
                                    className="w-full h-[100px] rounded-lg"
                                  />
                                  ) || (
                                      <img
                                        src={newspaperData.logo}
                                        alt="logo newspaper"
                                        className="w-full h-[100px] rounded-lg"
                                      />
                                    )}
                                </div>

                                <div className="w-2/3">
                                  <div className="float-left">
                                    <h2 className="text-md font-bold mt-2">
                                      {item.title}
                                    </h2>
                                    <p className="text-gray-600 text-md">{item.date}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <div className="group absolute bottom-2 right-1 transition ease-in-out delay-50 duration-300 backdrop-blur-[3px] px-2 rounded-lg bg-white bg-opacity-70 border-2 border-gray-700 hover:bg-gray-300 before:content-['?'] hover:before:content-['Wrong_Perception?']"
                            onClick={() => handleTweetDisagreeButton(item)}></div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={articlesNumber < allArticles.length ? "flex justify-center" : "hidden"}>
                    <button className="bg-gray-700 bg-opacity-0 hover:bg-opacity-30 border-2 border-gray-700 rounded-lg w-1/3 my-4 font-bold text-lg" onClick={handleLoadMore}>Load More</button>
                  </div>
                </div>
              </div>
            )}

            {isMobile && (
              <div className="flex">
                <div className=" mt-5 p-1 w-full">
                  <div className=" my-1">
                    <h2 className="text-xl font-bold mb-5  ">
                      Latest Articles about India by {newspaperData.name}
                    </h2>
                  </div>
                  <div className="grid grid-cols-1">
                    {newspaperArticles.map((item) => (
                      <div className="mb-4">
                        <div className="relative">
                          <a href={item.link}>
                            <div
                              className={`shadow-md z-0 border-4 bg-opacity-5 backdrop-blur-[3px] rounded-xl drop-shadow-lg w-full h-4/3  ${item.type === "Negative"
                                  ? "bg-custom-red border-custom-red"
                                  : item.type === "Neutral"
                                    ? "bg-custom-yellow2 border-custom-yellow2"
                                    : "bg-custom-green border-custom-green"
                                }`}
                            >
                              <div className="flex">
                                {item.image && (<img
                                  src={item.image}
                                  alt="illustration of article"
                                  className="object-cover w-1/3 mt-2 mb-2 ml-2 mr-2 rounded-lg"
                                />
                                ) || (
                                    <img
                                      src={newspaperData.logo}
                                      alt="logo newspaper"
                                      className="object-cover w-1/3 mt-2 mb-2 ml-2 mr-2 rounded-lg"
                                    />
                                  )}
                                <div className="float-left">
                                  <h2 className="text-md font-bold mt-2">
                                    {item.title}
                                  </h2>
                                  <p className="text-gray-600 text-md">{item.date}</p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className={articlesNumber < allArticles.length ? "flex justify-center" : "hidden"}>
                    <button className="bg-gray-700 bg-opacity-5 border-2 border-gray-700 rounded-lg w-2/3 my-2 font-bold" onClick={handleLoadMore}>Load More</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewspaperDetails;
