import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// Function to add Google Analytics script to the document
function addGoogleAnalyticsScript() {
  // Ensure that dataLayer is defined globally before using it
  window.dataLayer = window.dataLayer || [];

  const script = document.createElement('script');
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-94EW3KS2CV";
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    // Initialize gtag function and configure it
    function gtag() {
      window.dataLayer.push(arguments);
    }
    
    // Initialize Google Analytics
    gtag('js', new Date());
    gtag('config', 'G-94EW3KS2CV');
  };
}

// Add Google Analytics script when the app loads
addGoogleAnalyticsScript();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
